/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, ColumnCover, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Zájezdy"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--menu pb--12 pt--12" style={{"backgroundColor":"rgba(165,190,203,1)"}} menu={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/pagkustici"} target={""} content={"Chorvatsko - Pag"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/italie"} target={""} content={"Elba - Marina di Campo"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/elbasafari"} target={""} content={"Elba - safari"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/shagra"} target={""} content={"Egypt - Marsa Shagra"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/egypt-safari"} target={""} content={"Egypt safari"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/mexiko"} target={""} content={"Mexiko"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 btn-box--filling2 ff--3" href={"/ciernavoda"} target={""} content={"Slovensko"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":45,"marginBottom":0,"paddingBottom":38.28125}} name={"zacatek"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3" content={"<span style=\"color: rgb(199, 18, 18);\">Potápění v zahraničí</span>"}>
              </Title>

              <Text className="text-box ff--3" style={{"maxWidth":910}} content={"<span style=\"color: rgb(0, 0, 0);\">Cestovní kancelář ORCA organizuje potápění od Elby po Mexiko, Všechny potápěčské destinace v naší nabídce jsou vhodné jak pro potápěče tak i pro nepotápěče.\n\nVšude, kam s námi pojedete, Vás bude doprovázet zkušený instruktor potápění. Na lokalitách Elba, Korsika a Pag je možné absolvovat celý kurz potápění.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <ColumnCover name={"pq77ptvxzf"}>
        </ColumnCover>


        <Column style={{"paddingTop":24,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --left el--4" style={{"maxWidth":1319,"paddingLeft":0,"marginBottom":10,"paddingRight":0,"paddingBottom":0}} columns={"4"}>
            
            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/ciernavoda\">Slovensko<br>Čierna Voda<br></a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=860x_.jpg"} use={"page"} href={"/ciernavoda"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">8. - 11. 8.<br></span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">2 150&nbsp;Kč&nbsp;<br><br>Bohatě zarybněné jezero s vyvázanými atrakcemi pro potápěče, plážová zóna s občerstvením a relax u vody.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/elbasafari\">Itálie&nbsp;<br>ostrov&nbsp;Elba<br>malé safari</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=660x_.jpg"} use={"page"} href={"/elbasafari"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">13. - 20. 9.</span><br><span style=\"color: rgb(25, 163, 38);\">volná 3 místa</span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">24 800 Kč&nbsp;<br><br>Malý kolektiv, skromnější ubytování, ale přátelská atmosféra a lokality, na které se ze břehu jen tak nepodíváte.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/pagkustici\">Chorvatsko<br>ostrov Pag<br>Kustići</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg"} use={"page"} href={"/pagkustici"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Podzimní termín 2024<br><span style=\"color: rgb(5, 5, 5);\">11. - 15. 10.</span><br><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">6 050 Kč</span><br><span style=\"color: rgb(201, 33, 33);\">Volno již jen pro kurzisty</span><span style=\"color: rgb(0, 0, 0);\">&nbsp;<br><br>Potápění v Jaderském moři na ostrově PAG s možností dokončit praktickou část všech kurzů potápění.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/egypt-safari\">Egypt - safari<br>&nbsp;Daedalus<br>Elphinstone</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=660x_.jpg"} use={"page"} href={"/egypt-safari"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">23. - 30. 11.<br></span><span style=\"color: rgb(47, 200, 35);\">volných 5 míst</span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">37 750 Kč<br><br>Potápění na útesech v Rudém moři. Safari za korálovými zahradami, hejny dravých ryb a žraloky.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/mexiko\">Mexiko<br>Yucatán<br>Tulum</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/d6bee8af36cc450fb13b11c1b823249e_s=350x_.jpg"} use={"page"} href={"/mexiko"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/d6bee8af36cc450fb13b11c1b823249e_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">24. 2. - 10. 3.<br>10. 3. - 24. 3.<br></span><br>&nbsp;Cena nepotápěči: <span style=\"color: rgb(0, 0, 0);\">22&nbsp;125 Kč</span><br>Cena potápěči: <span style=\"color: rgb(0, 0, 0);\">42 380 Kč<br><br>Cenoty a jeskynní systémy Yucatánského poloostrova.&nbsp;<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/shagra\">Egypt<br>Marsa<br>Shagra</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=860x_.jpg"} use={"page"} href={"/shagra"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">20. - 27. 4.<br></span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">upravujeme<br><br>Neomezené potápění ze břehu, výjezdy na vzdálenější lokality a plná penze v čistém eko-resortu.<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ph8nq4phayo"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":12.524993896484377}} name={"ec4n9uuqajr"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape5 btn-box--cColor2 btn-box--center ff--3" href={"https://cdn.swbpg.com/d/13078/vseobecne-podminky-ck-orca-diving-s-r-o-pdf"} content={"Všeobecné podmínky CK Orca Diving s.r.o."}>
              </Button>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape5 btn-box--cColor2 btn-box--center ff--3" href={"https://cdn.swbpg.com/d/13078/certifikat.pdf"} target={"_blank"} content={"Pojištění proti úpadku"} document={"291469a4776c4584ac35cb27e179a35f"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}